import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import getNotifStatus from "./getNotifStatus";
import getLocation from "./getLocation";
import NotifItem from "./index";
import "../../components/LogOutMenu/_DropdownMenu.scss";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles();

const OneItem = ({
  id,
  dispatch,
  notif,
  handleClose,
  handleClick,
  statusList,
}) => {
  let { unreadNotificationsCount } = useSelector(
    (state) => state.notifications
  );
  const user = useSelector((state) => state.user.fetchMe);
  const myLeaves = useSelector((state) => state?.leave?.getMyLeaves?.leaves);
  let myAuthorisation = useSelector(
    (state) => state?.authorisation?.myAuthorisation?.authorisations
  );
  const { AllLeaves } = useSelector((state) => state?.leave);
  const { allAuthorisations } = useSelector((state) => state.authorisation);

  const isTeamLead = user.role?.code === "TEAM LEAD";
  const isEmployee = user.role?.code === "EMPLOYEE";
  const HOLIDAY_CONGRATULATION = notif?.code === "holiday-congratulation";

  return (
    <div
      className={
        notif?.seen === true
          ? "mainItemwapper"
          : "mainItemwapper main-item-wrapper-seen"
      }
    >
      <MenuItem>
        <Link
          to={
            HOLIDAY_CONGRATULATION
              ? "/settings/holidays"
              : isTeamLead || isEmployee
              ? myLeaves?.find((el) => el?._id === notif?.leave?._id) ||
                myAuthorisation?.find(
                  (el) => el?._id === notif?.authorisation?._id
                )
                ? "/myspace"
                : notif.authorisation
                ? "/authorisations"
                : "/leaves"
              : notif.authorisation
              ? "/authorisations"
              : "/leaves"
          }
          state={{
            isHoliday: notif.holiday ? true : false,
            isAuthorisation: notif.authorisation ? true : false,
            holidayId: notif.holiday,
            status: getLocation(
              user?.role?.code,
              isEmployee && notif.leave
                ? myLeaves
                : isEmployee && notif.authorisation
                ? myAuthorisation
                : notif.authorisation
                ? allAuthorisations
                : AllLeaves,
              notif.authorisation ? notif.authorisation : notif?.leave,
              statusList
            ),
            originalStatus: getNotifStatus(user.role?.code, notif?.code),
            leaveID: notif.authorisation
              ? notif?.authorisation?._id
              : notif?.leave?._id,
          }}
          style={{
            display: "flex",
            textDecoration: "none",
            flexWrap: "wrap",
          }}
        >
          <ListItemIcon onClick={handleClose}>
            <NotifItem
              notif={notif}
              id={notif?._id}
              img={notif?.notification_by?.img}
              date={new Date() - new Date(notif?.createdAt)}
              DAY={new Date(notif?.createdAt)}
              fullName={notif?.notification_by?.fullName}
              dep={notif?.notification_by?.department}
              code={notif?.code}
              dispatch={dispatch}
              unreadNotificationsCount={unreadNotificationsCount}
            />
          </ListItemIcon>
        </Link>
        <div className="MoreHorizIcon-icon">
          <Tooltip title="Delete this notification" arrow>
            <DeleteForeverIcon
              onClick={() => handleClick({ id, seen: notif.seen })}
            />
          </Tooltip>
        </div>
      </MenuItem>
    </div>
  );
};

export default OneItem;
