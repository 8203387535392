import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import "../../components/LogOutMenu/_DropdownMenu.scss";
import Tooltip from "@mui/material/Tooltip";
import {
  deleteNotification,
  handleRemoveNotifications,
} from "../../slices/notifications";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OneItem from "./OneItem";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";

const NotificationMenu = (props) => {
  const {
    unreadNotificationsCount,
    notifications,
    dispatch,
    seenNotifications,
    handleClose,
  } = props;

  const { statusList } = useSelector((state) => state.status);
  const handleClick = (notifItem) => {
    dispatch(deleteNotification(notifItem.id));
    if (!notifItem.seen) {
      dispatch(handleRemoveNotifications(unreadNotificationsCount - 1));
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="Notif_container__">
      <div className="Notif_container__header">
        <div className="Notif_container__big__header" onClick={handleClose}>
          <div> Notifications </div>

          <Tooltip title="Close notifications" arrow>
            <div className="MoreHorizIcon-icon-notification">
              <CloseIcon onClick={handleClose} />
            </div>
          </Tooltip>
        </div>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="All" {...a11yProps(0)} />
              <Tab label="Not Seen " {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {notifications.length === 0 ? (
              <>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon> No Notifications ...</ListItemIcon>
                </MenuItem>
              </>
            ) : (
              notifications
                .map((notif, index) => (
                  <>
                    <OneItem
                      id={notif._id}
                      notif={notif}
                      dispatch={dispatch}
                      handleClose={handleClose}
                      index={index}
                      handleClick={handleClick}
                      statusList={statusList}
                    />
                  </>
                ))
                .reverse()
            )}
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            handleClose={!handleClose}
            style={{ left: "1275px !important" }}
          >
            {seenNotifications.length === 0 ? (
              <>
                <MenuItem onClick={handleClose} style={{marginTop:"5px"}}>
                  <ListItemIcon> No Notifications ...</ListItemIcon>
                </MenuItem>
              </>
            ) : (
              seenNotifications
                ?.map((notif, index) => (
                  <OneItem
                    id={notif._id}
                    dispatch={dispatch}
                    notif={notif}
                    handleClose={handleClose}
                    index={index}
                    handleClick={handleClick}
                    statusList={statusList}
                  />
                ))
                .reverse()
            )}
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default NotificationMenu;
