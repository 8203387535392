import { useDispatch, useSelector } from "react-redux";
import * as React from "react";
import { getNotification } from "../../slices/notifications";
import { fetchDepartment } from "../../slices/departement";
import { getAllStatus } from "../../slices/status";
import getAccess from "../../utilities/getAccess";
import NotificationMenu from "./NotificationMenu";
import { fetchUser } from "../../slices/user";
import Menu from "@mui/material/Menu";
import { useStyles } from "./styles";
import { useEffect } from "react";
import "./_NotifItem.scss";

export default function AccountMenu() {
  const dispatch = useDispatch();
  const classes = useStyles();
  let { notifications, NotificationStatus, unreadNotificationsCount } =
    useSelector((state) => state.notifications);

  const seenNotifications = notifications.filter(
    (notification) => notification.seen === false
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.user?.fetchMe);

  useEffect(() => {
    dispatch(getNotification());
  }, [open, unreadNotificationsCount]);

  useEffect(() => {
    if (
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "USER",
        "LIST"
      )
    ) {
      dispatch(fetchUser());
    }
    if (
      user &&
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "STATUS",
        "LIST"
      )
    ) {
      dispatch(getAllStatus());
    }
  }, [dispatch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "DEPARTMENT",
        "LIST"
      )
    ) {
      dispatch(fetchDepartment());
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div onClick={handleClick}>
        <div className="icon-button">
          <div>
            <span className="material-icons">notifications</span>
            {NotificationStatus === "succeeded" &&
              unreadNotificationsCount > 0 && (
                <span className="icon-button__badge">
                  {unreadNotificationsCount > 99
                    ? "+99"
                    : unreadNotificationsCount}
                </span>
              )}
          </div>
        </div>
      </div>

      <Menu
        anchorEl={anchorEl}
        PaperProps={{ sx: { minWidth: "550px" } }}
        id="account-menu"
        open={open}
        className={`${classes.dropdownMenuMain} "dropdownMenuMainScss"`}
        onClose={handleClose}
      >
        <NotificationMenu
          unreadNotificationsCount={unreadNotificationsCount}
          handleClose={handleClose}
          seenNotifications={seenNotifications}
          notifications={notifications}
          dispatch={dispatch}
          anchorEl={anchorEl}
          open={open}
        />
      </Menu>
    </>
  );
}
